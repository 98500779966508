import { FunctionComponent, Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { lazy } from "@loadable/component";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";

import { getConfigs } from "@ducks/config";
import { initUlmUuid, initUlmHandlerTriggered } from "@ducks/ulm";
import { disneyActivatePath } from "@constants/paths";
import settings from "@settings";
import { AppState, history } from "@store";
import defaultTheme from "@theme/default";

import Header from "@components/Header";
import BaseMeta from "@components/BaseMeta";
import ScrollToTop from "@components/ScrollToTop";
import ShopError from "@components/ShopError";
import SuspenseFallback from "@components/SuspenseFallback";
import { NavProvider, Footer, LiveChat } from "acm-components";
import "@acm-components/css";

const navV3UlmTesting = "https://digital-fortress-dev.eco.astro.com.my/dev/config/p0gvgKWv6d/config.json";

const LandingRedirection = lazy(() => import(/* webpackChunkName: "landingRedirection" */ "@pages/LandingRedirection"));
const AppsRouting = lazy(() => import(/* webpackChunkName: "appsRouting" */ "./AppsRouting"));

const App: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isUnderMaintenance = useSelector<AppState, boolean>((state) => state.config.app.maintenanceMode);

  useEffect(() => {
    dispatch(getConfigs());
    dispatch(initUlmUuid());
    dispatch(initUlmHandlerTriggered());
  }, [dispatch]);

  return (
    <MuiThemeProvider theme={defaultTheme}>
      {/* CSS reset */}
      <CssBaseline />

      <NavProvider portal="acm" environment={settings.navEnv} dfEndpoint={navV3UlmTesting}>
        {({ config: navConfig }) => (
          <>
            <Header config={navConfig.header} />
            <Suspense fallback={<SuspenseFallback />}>
              <main>
                {/* Base meta tags */}
                <BaseMeta />

                {/* Pages */}
                {isUnderMaintenance ? (
                  <ShopError errorCode="MAINTENANCE" />
                ) : (
                  <ConnectedRouter history={history}>
                    <ScrollToTop />
                    <Switch>
                      <Route path={`/${disneyActivatePath}`}>
                        <AppsRouting />
                      </Route>
                      <Route exact path="/">
                        <LandingRedirection />
                      </Route>
                      <Route path="*">
                        <ShopError errorCode="NOT_FOUND" />
                      </Route>
                    </Switch>
                  </ConnectedRouter>
                )}
              </main>
              <Footer config={navConfig.footer} />
              <LiveChat config={navConfig.liveChat} />
            </Suspense>
          </>
        )}
      </NavProvider>
    </MuiThemeProvider>
  );
};

App.displayName = "App";

export default App;
